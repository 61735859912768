import Amplitude from "amplitude-js";
// import config from "../config";

const amplitude = Amplitude.getInstance();

const AmplitudeHelper = {
  init: () => {
    //    amplitude.init(config.AMPLITUDE_KEY);
  },
  logEvent: async (eventName, data) => {
    //  amplitude.logEvent(eventName, data);
  },
  clearUserProperties: () => {
    amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
